import { ClientRole } from "~/composables/enums/ClientRole";

export function getProfileRoute(): string {
  const client = useClient();

  if (client.role === ClientRole.Admin) {
    return '/admin/dashboard';
  } else if (client.role === ClientRole.Employer) {
    return `/company/${client.getActiveCompany.id}/vacancies${client.getActiveCompany.isVacancyCreated ? '' : '/preview/responses'}`;
  }

  return '/applicant/responses';
}